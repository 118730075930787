.providerSelectContainer {
  display: block;
  position: relative;
  margin-left: 2%;
  padding-right: 3.5%;
  margin-bottom: 4%;
  min-height: 63vh;
}

.tableProviderSelect {
  display: block;
  margin-left: 2%;
  padding-right: 3.5%;
}

.userProviderSelectSearchBarContainer {
  display: inline-block;
  margin-left: 81.5%;
  width: 15%;
  margin-top: 3%;
}

.providerSelectLegend {
  display: block;
  position: relative;
  margin-left: 1%;
  margin-top: 4%;
  margin-bottom: -3%;
  font-weight: bold;
}

.userProviderSelectSearchIconContainer {
  display: inline-block;
  position: relative;
  margin-left: 0.5%;
  width: 3%;
}

.userProviderSelectSearchBar {
  display: inline-block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #cfcdca;
  font-weight: bold;
}

.userProviderSelectSearchIcon {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.userProviderSelectSearchIcon:hover {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  color: #009639;
  width: 40px;
  height: 40px;
}

.iconGoToProviderSelect {
  width: 30px;
  height: 30px;
  color: #009639;
  cursor: pointer;
}

.iconGoToProviderSelect:hover {
  width: 35px;
  height: 35px;
  color: #ff0000;
  cursor: pointer;
}