.loadingLogo {
    height: 120px;
    animation: 1s infinite normal idle;
}

@keyframes idle {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

