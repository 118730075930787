.documentSearchContainer {
  min-height: 79vh;
}

.documentUploadContainer {
  display: inline-block;
  margin-left: 4%;
  margin-top: 2%;
  min-width: 80%;
  min-height: 75vh;
}

body {
  margin: 0;
  padding: 0;
}

.iuDocumentSearchBody {
  display: inline-block;
  margin-left: 4%;
  margin-top: 5%;
  width: 100%;
}

.documentSearchBodyVoidResultMsg {
  display: inline-block;
  position: absolute;
  width: 80%;
  margin-left: 2%;
  font-weight: bold;
}

.documentSearchFormContainer {
  display: inline-block;
  width: 27%;
  margin-left: 3%;
  margin-top: 3%;
}

.inputUploadDigDoc {
  display: inline-block;
  background-color: transparent;
}

.documentSearchFormContainer1 {
  display: inline-block;
  width: 12.5%;
  margin-left: 3%;
  margin-top: 3%;
}

.documentSearchFormContainer2 {
  display: inline-block;
  width: 12.5%;
  margin-left: 3%;
  margin-top: 3%;
}

.documentSearchFormContainer3 {
  display: inline-block;
  width: 27%;
  margin-left: 3%;
  margin-top: 3%;
}

.documentSearchBackContainer {
  display: inline-block;
  width: 150px;
  margin-left: 1%;
  margin-top: 2%;
  cursor: pointer;
}

.documentSearchBackContainer15 {
  display: inline-block;
  width: 150px;
  margin-left: 1%;
  margin-top: -10%;
  margin-bottom: 3%;
  cursor: pointer;
}

.documentSearchBackLegend {
  display: inline-block;
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  margin-left: 0.5%;
  margin-top: 0.5%;
}

.documentSearchLegendContainer {
  display: block;
  font-weight: bold;
  font-size: 28px;
  margin-top: 2%;
  margin-left: 2%;
}

.documentSearchBackIcon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 1%;
}

.documentSearchBackIcon:hover {
  display: inline-block;
  width: 45px;
  height: 45px;
  margin-left: 1%;
  background-color: lavender;
}

.documentSearchFormLegend {
  display: block;
  width: 150px;
  font-size: 17px;
  color: #a9a9a9;
  margin-top: 5%;
  margin-bottom: -4%;
  margin-left: 0.5%;
}

.documentSearchFormInput {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  margin-top: 5%;
  background-color: #f4f4f4;
  border: none;
  border-bottom: 1px solid #808080;
}

.documentSearchFormLegend1 {
  display: block;
  width: 250px;
  font-size: 17px;
  color: #a9a9a9;
  margin-left: 12%;
}

.documentSearchFormInput1 {
  display: inline-block;
  width: 70%;
  font-size: 16px;
  margin-left: 12%;
  background-color: #f4f4f4;
  border: none;
  border-bottom: 1px solid #808080;
}

.documentSearchFormInput2 {
  display: inline-block;
  width: 70%;
  font-size: 16px;
  margin-top: 10%;
  background-color: #f4f4f4;
  border: none;
  border-bottom: 1px solid #808080;
}

.documentSearchFormLegend2 {
  display: block;
  width: 150px;
  font-size: 17px;
  color: #a9a9a9;
  margin-top: 5%;
  margin-bottom: -4%;
  margin-left: 0.5%;
}

.documentSearchFormLegend3 {
  display: block;
  width: 150px;
  font-size: 17px;
  color: #a9a9a9;
  margin-top: 5%;
  margin-bottom: 0%;
  margin-left: 0.5%;
}

.documentSearchFormSelect {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  margin-top: 5%;
  background-color: #f4f4f4;
  border: none;
  border-bottom: 1px solid #808080;
}

.documentSearchFormScheduleIcon {
  display: inline;
  position: absolute;
  width: 25px;
  height: 25px;
  margin-top: 0;
  margin-left: -9%;
}

.documentSearchFormScheduleIcon1 {
  display: inline;
  position: absolute;
  width: 25px;
  height: 25px;
  margin-top: 0;
  margin-left: -9%;
}

.documentSearchFormBtnContainer {
  display: block;
  margin-top: 4%;
  margin-left: 84%;
  margin-bottom: 10.5%;
}

.documentSearchResContainer {
  display: block;
  margin-top: -7%;
  margin-left: 4%;
}

.documentSearchResultLegend {
  display: block;
  font-weight: bold;
  font-size: 28px;
}

.documentSearchResultResultsContainer {
  display: block;
  width: 190%;
}

.documentSearchResultLegend1 {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  color: #969696;
  width: auto;
  margin-top: 2%;
}

.documentSearchResultLegend2 {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  color: #969696;
  width: auto;
  margin-left: 4%;
  margin-top: 2%;
}

.documentSearchResultLegend3 {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  color: #969696;
  width: auto;
  margin-left: 8%;
  margin-top: 2%;
}

.documentSearchResultLegend4 {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  color: #969696;
  width: auto;
  margin-left: 6%;
  margin-top: 2%;
}

.documentSearchResultLegend12 {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  color: #969696;
  width: auto;
  margin-left: 7%;
  margin-top: 2%;
}

.documentSearchResultShowDataResultContainer {
  display: inline-block;
  margin-left: 20%;
}

.documentSearchResultLegend5 {
  display: inline-block;
  position: relative;
  font-weight: bold;
  font-size: 14px;
  color: #bababa;
  width: 5%;
  margin-left: 1%;
  overflow: hidden;
}

.documentSearchResultLegend6 {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: #bababa;
  width: 5%;
  margin-left: 0%;
  overflow: hidden;
}

.documentSearchResultLegend7 {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: #bababa;
  width: 10%;
  margin-left: 0%;
  overflow: hidden;
}

.documentSearchResultLegend8 {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: #bababa;
  width: 8%;
  margin-left: 0%;
  overflow: hidden;
}

.documentSearchResultLegend9 {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: #bababa;
  width: 7%;
  margin-left: 0%;
  overflow: hidden;
}

.documentSearchResultLegend10 {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: #bababa;
  width: 12%;
  margin-left: 0%;
  margin-top: 2%;
  color: #009639;
  overflow: hidden;
}

.rejectDocForm {
  display: inline-block;
  position: absolute;
  width: 27%;
  margin-top: 1.3%;
  margin-left: 0%;
  background-color: #e8ebeb;
  border-color: #f0f0f0;
}

.rejectDocumentFormContainer {
  display: inline-block;
  position: relative;
  margin-top: 5%;
  width: 100%;
  height: 300px;
}

.rejectDocForm {
  display: inline-block;
  width: 25%;
  margin-left: 400px;
}
.rejectDocForm1 {
  display: inline-block;
  color: #969696;
  margin-top: 30px;
  width: auto;
  font-weight: bold;
  font-size: 16px;
  margin-left: 400px;
}

.rejectDocForm2 {
  display: inline-block;
  width: 25%;
  height: 250px;
  margin-left: 400px;
  background-color: lavender;
}

.rejectDocForm3 {
  display: inline-block;
  color: #969696;
  margin-top: 30px;
  width: auto;
  font-weight: bold;
  font-size: 16px;
  margin-left: 400px;
}

.rejectDocsStyle {
  display: inline-block;
  position: absolute;
  font-weight: bold;
  font-size: 14px;
  color: #969696;
  margin-left: 0.5%;
  margin-bottom: 1%;
}

.rejectDocumentFormContainer {
  display: block;
  margin-top: 5%;
  width: 100%;
}

.documentSearchResultIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 0%;
  cursor: pointer;
  margin-right: 1rem;
}

.documentUploadBtnContainer {
  display: block;
  margin-left: 3%;
  margin-top: 3%;
  margin-bottom: 2%;
  width: 100%;
}

.documentUploadBtn {
  display: block;
  background-color: white;
  border: 1px solid #009639;
  color: #009639;
  font-weight: bold;
  font-size: 12px;
  border-radius: 0.35em;
  min-width: 20%;
  height: 30px;
  margin-left: 0%;
}

.documentUploadBtnContainer1 {
  display: block;
  margin-left: 30%;
  margin-top: 4%;
  width: 25%;
}

.documentUploadBtn1 {
  display: block;
  background-color: #009639;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 12px;
  border-radius: 0.35em;
  min-width: 100%;
  height: 30px;
  margin-top: 10%;
}

.documentUploadBtn6 {
  display: block;
  background-color: #009639;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 12px;
  border-radius: 0.35em;
  min-width: 15%;
  height: 30px;
  margin-top: 1%;
  margin-left: 460px;
}
.documentUploadBtn6:hover {
  display: block;
  background-color: #00bb2d;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 12px;
  border-radius: 0.35em;
  min-width: 15%;
  height: 30px;
  margin-top: 1%;
  margin-left: 460px;
}

.documentUploadDeleteIcon {
  display: inline-block;
  color: darkred;
  margin-left: 1%;
}
.documentUploadDeleteIcon1 {
  display: inline-block;
  color: darkred;
  margin-right: 0.5%;
  margin-left: 0.75%;
}

.documentUploadViewFileContainer {
  display: block;
  margin-left: 3%;
}

.documentUploadViewFileContainerContainer {
  display: block;
  height: auto;
  width: 100%;
}

.documentUploadFailedUploadContainer {
  display: block;
  margin-left: 2%;
  margin-top: 2%;
}

.documentUploadDescriptionFiles {
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
}

.documentUploadLegend {
  display: block;
  margin-left: 2%;
  font-weight: bold;
  font-size: 28px;
  margin-top: 5%;
}

.documentUploadLegend1 {
  display: block;
  margin-left: 3%;
  font-weight: bold;
  font-size: 14px;
  margin-top: 0%;
}

.documentUploadLegend2 {
  display: block;
  margin-left: 3%;
  font-size: 14px;
  margin-top: 1%;
}

.documentUploadLegend4 {
  display: block;
  margin-left: 3%;
  font-size: 14px;
  margin-top: 0%;
}

.documentUploadLegend3 {
  display: block;
  margin-top: 2%;
  font-weight: bold;
  font-size: 14px;
  margin-left: 3%;
}
