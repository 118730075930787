
/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #BBB; 
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}

.bold{
  font-weight: 600 !important;
}

.mainBox {
  margin: 0 auto;
  width: 80%;
}

@media (max-width: 1600px) {
  .mainBox {
    width: 90%;
  }
}